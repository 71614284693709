import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';

export interface SearchCountry {
    code: string;
    name: string;
}

export interface MadridCountry {
    country_name: string;
    country_code: string;
}

@Injectable({
  providedIn: 'root'
})
export class GetCountriesService {
    private allCountries: any[] = [
        { code: 'UA', name: 'Ukraine' },
        { code: 'EU', name: 'EU European Union' },
        { code: 'GB', name: 'United Kingdom' },
        { code: 'US', name: 'United States of America' },
        { code: 'ES', name: 'Spain' },
        { code: 'PT', name: 'Portugal' },
        { code: 'PL', name: 'Poland' },
        { code: 'AF', name: 'Afghanistan' },
        { code: 'AL', name: 'Albania' },
        { code: 'DZ', name: 'Algeria' },
        { code: 'AS', name: 'American Samoa' },
        { code: 'AD', name: 'Andorre' },
        { code: 'AO', name: 'Angola' },
        { code: 'AI', name: 'Anguilla' },
        { code: 'AQ', name: 'Antarctica' },
        { code: 'AG', name: 'Antigua and Barbuda' },
        { code: 'AR', name: 'Argentina' },
        { code: 'AM', name: 'Armenia' },
        { code: 'AW', name: 'Aruba' },
        { code: 'AU', name: 'Australia' },
        { code: 'AT', name: 'Austria' },
        { code: 'AZ', name: 'Azerbaijan' },
        { code: 'BS', name: 'Bahamas' },
        { code: 'BH', name: 'Bahrain' },
        { code: 'BD', name: 'Bangladesh' },
        { code: 'BB', name: 'Barbade' },
        { code: 'BE', name: 'Belgium' },
        { code: 'BZ', name: 'Belize' },
        { code: 'BJ', name: 'Benin' },
        { code: 'BM', name: 'Bermuda' },
        { code: 'BT', name: 'Bhutan' },
        { code: 'BO', name: 'Bolivia' },
        { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
        { code: 'BA', name: 'Bosnia and Herzegovina' },
        { code: 'BW', name: 'Botswana' },
        { code: 'BV', name: 'Bouvet Island' },
        { code: 'BR', name: 'Brazil' },
        { code: 'BX', name: 'Benelux' },
        { code: 'IO', name: 'British Indian Ocean Territory' },
        { code: 'VG', name: 'British Virgin Islands' },
        { code: 'BN', name: 'Brunei' },
        { code: 'BG', name: 'Bulgaria' },
        { code: 'BF', name: 'Burkina Faso' },
        { code: 'BI', name: 'Burundi' },
        { code: 'KH', name: 'Cambodia' },
        { code: 'CM', name: 'Cameroon' },
        { code: 'CA', name: 'Canada' },
        { code: 'CV', name: 'Cape Verde' },
        { code: 'KY', name: 'Cayman Islands' },
        { code: 'CF', name: 'Central African Republic' },
        { code: 'TD', name: 'Chad' },
        { code: 'CL', name: 'Chile' },
        { code: 'CN', name: 'China' },
        { code: 'CX', name: 'Christmas Island' },
        { code: 'CC', name: 'Cocos (Keeling) Islands' },
        { code: 'CO', name: 'Colombia' },
        { code: 'KM', name: 'Comoros' },
        { code: 'CG', name: 'Congo' },
        { code: 'CD', name: 'Congo (Dem. Rep.)' },
        { code: 'CK', name: 'Cook Islands' },
        { code: 'CR', name: 'Costa Rica' },
        { code: 'ME', name: 'Crna Gora' },
        { code: 'HR', name: 'Croatia' },
        { code: 'CU', name: 'Cuba' },
        { code: 'CW', name: 'Curaçao' },
        { code: 'CY', name: 'Cyprus' },
        { code: 'CZ', name: 'Czech Republic' },
        // tslint:disable-next-line:quotemark
        { code: 'CI', name: "Côte D'Ivoire" },
        { code: 'DK', name: 'Denmark' },
        { code: 'DJ', name: 'Djibouti' },
        { code: 'DM', name: 'Dominica' },
        { code: 'DO', name: 'Dominican Republic' },
        { code: 'TL', name: 'East Timor' },
        { code: 'EC', name: 'Ecuador' },
        { code: 'EG', name: 'Egypt' },
        { code: 'SV', name: 'El Salvador' },
        { code: 'GQ', name: 'Equatorial Guinea' },
        { code: 'ER', name: 'Eritrea' },
        { code: 'EE', name: 'Estonia' },
        { code: 'ET', name: 'Ethiopia' },
        { code: 'FK', name: 'Falkland Islands' },
        { code: 'FO', name: 'Faroe Islands' },
        { code: 'FJ', name: 'Fiji' },
        { code: 'FI', name: 'Finland' },
        { code: 'FR', name: 'France' },
        { code: 'GF', name: 'French Guiana' },
        { code: 'PF', name: 'French Polynesia' },
        { code: 'TF', name: 'French Southern Territories' },
        { code: 'GA', name: 'Gabon' },
        { code: 'GM', name: 'Gambia' },
        { code: 'DE', name: 'Germany' },
        { code: 'GH', name: 'Ghana' },
        { code: 'GI', name: 'Gibraltar' },
        { code: 'GR', name: 'Greece' },
        { code: 'GL', name: 'Greenland' },
        { code: 'GD', name: 'Grenada' },
        { code: 'GP', name: 'Guadeloupe' },
        { code: 'GU', name: 'Guam' },
        { code: 'GT', name: 'Guatemala' },
        { code: 'GG', name: 'Guernsey and Alderney' },
        { code: 'GN', name: 'Guinea' },
        { code: 'GW', name: 'Guinea-Bissau' },
        { code: 'GY', name: 'Guyana' },
        { code: 'HT', name: 'Haiti' },
        { code: 'HM', name: 'Heard and McDonald Islands' },
        { code: 'HN', name: 'Honduras' },
        { code: 'HK', name: 'Hong Kong' },
        { code: 'HU', name: 'Hungary' },
        { code: 'IS', name: 'Iceland' },
        { code: 'IN', name: 'India' },
        { code: 'ID', name: 'Indonesia' },
        { code: 'IR', name: 'Iran' },
        { code: 'IQ', name: 'Iraq' },
        { code: 'IE', name: 'Ireland' },
        { code: 'IM', name: 'Isle of Man' },
        { code: 'IL', name: 'Israel' },
        { code: 'IT', name: 'Italy' },
        { code: 'JM', name: 'Jamaica' },
        { code: 'JP', name: 'Japan' },
        { code: 'JE', name: 'Jersey' },
        { code: 'JO', name: 'Jordan' },
        { code: 'KE', name: 'Kenya' },
        { code: 'KI', name: 'Kiribati' },
        { code: 'KP', name: 'Korea (North)' },
        { code: 'KR', name: 'Korea (South)' },
        { code: 'KW', name: 'Kuwait' },
        { code: 'LA', name: 'Laos' },
        { code: 'LV', name: 'Latvia' },
        { code: 'LB', name: 'Lebanon' },
        { code: 'LS', name: 'Lesotho' },
        { code: 'LR', name: 'Liberia' },
        { code: 'LY', name: 'Libya' },
        { code: 'LI', name: 'Liechtenstein' },
        { code: 'LT', name: 'Lithuania' },
        { code: 'LU', name: 'Luxembourg' },
        { code: 'MO', name: 'Macao' },
        { code: 'MK', name: 'Macedonia' },
        { code: 'MG', name: 'Madagascar' },
        { code: 'MW', name: 'Malawi' },
        { code: 'MY', name: 'Malaysia' },
        { code: 'MV', name: 'Maldives' },
        { code: 'ML', name: 'Mali' },
        { code: 'MT', name: 'Malta' },
        { code: 'MH', name: 'Marshall Islands' },
        { code: 'MQ', name: 'Martinique' },
        { code: 'MR', name: 'Mauritania' },
        { code: 'MU', name: 'Mauritius' },
        { code: 'YT', name: 'Mayotte' },
        { code: 'MX', name: 'Mexico' },
        { code: 'FM', name: 'Micronesia' },
        { code: 'MD', name: 'Moldova' },
        { code: 'MC', name: 'Monaco' },
        { code: 'MS', name: 'Montserrat' },
        { code: 'MA', name: 'Morocco' },
        { code: 'MZ', name: 'Mozambique' },
        { code: 'MM', name: 'Myanmar' },
        { code: 'NA', name: 'Namibia' },
        { code: 'NR', name: 'Nauru' },
        { code: 'NP', name: 'Nepal' },
        { code: 'NL', name: 'Netherlands' },
        { code: 'AN', name: 'Netherlands Antilles' },
        { code: 'NC', name: 'New Caledonia' },
        { code: 'NZ', name: 'New Zealand' },
        { code: 'NI', name: 'Nicaragua' },
        { code: 'NE', name: 'Niger' },
        { code: 'NG', name: 'Nigeria' },
        { code: 'NU', name: 'Niue' },
        { code: 'NF', name: 'Norfolk Island' },
        { code: 'MP', name: 'Northern Mariana Islands' },
        { code: 'NO', name: 'Norway' },
        { code: 'OM', name: 'Oman' },
        { code: 'PK', name: 'Pakistan' },
        { code: 'PW', name: 'Palau' },
        { code: 'PS', name: 'Palestine' },
        { code: 'PA', name: 'Panama' },
        { code: 'PG', name: 'Papua New Guinea' },
        { code: 'PY', name: 'Paraguay' },
        { code: 'PE', name: 'Peru' },
        { code: 'PH', name: 'Philippines' },
        { code: 'PN', name: 'Pitcairn' },
        { code: 'PR', name: 'Puerto Rico' },
        { code: 'QA', name: 'Qatar' },
        { code: 'RO', name: 'Romania' },
        { code: 'RU', name: 'Russia' },
        { code: 'RW', name: 'Rwanda' },
        { code: 'RE', name: 'Réunion' },
        { code: 'BL', name: 'Saint Barthélemy' },
        { code: 'SH', name: 'Saint Helena' },
        { code: 'KN', name: 'Saint Kitts and Nevis' },
        { code: 'LC', name: 'Saint Lucia' },
        { code: 'MF', name: 'Saint Martin' },
        { code: 'PM', name: 'Saint Pierre and Miquelon' },
        { code: 'VC', name: 'Saint Vincent and the Grenadines' },
        { code: 'WS', name: 'Samoa' },
        { code: 'SM', name: 'San Marino' },
        { code: 'SA', name: 'Saudi Arabia' },
        { code: 'SN', name: 'Senegal' },
        { code: 'RS', name: 'Serbia' },
        { code: 'SC', name: 'Seychelles' },
        { code: 'SL', name: 'Sierra Leone' },
        { code: 'SG', name: 'Singapore' },
        { code: 'SX', name: 'Sint Maarten' },
        { code: 'SK', name: 'Slovakia' },
        { code: 'SI', name: 'Slovenia' },
        { code: 'SB', name: 'Solomon Islands' },
        { code: 'SO', name: 'Somalia' },
        { code: 'ZA', name: 'South Africa' },
        { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
        { code: 'SS', name: 'South Sudan' },
        { code: 'LK', name: 'Sri Lanka' },
        { code: 'SD', name: 'Sudan' },
        { code: 'SR', name: 'Suriname' },
        { code: 'SJ', name: 'Svalbard and Jan Mayen' },
        { code: 'SZ', name: 'Swaziland' },
        { code: 'SE', name: 'Sweden' },
        { code: 'CH', name: 'Switzerland' },
        { code: 'SY', name: 'Syria' },
        { code: 'ST', name: 'São Tomé and Príncipe' },
        { code: 'TW', name: 'Taiwan' },
        { code: 'TJ', name: 'Tajikistan' },
        { code: 'TZ', name: 'Tanzania' },
        { code: 'TH', name: 'Thailand' },
        { code: 'TG', name: 'Togo' },
        { code: 'TK', name: 'Tokelau' },
        { code: 'TO', name: 'Tonga' },
        { code: 'TT', name: 'Trinidad and Tobago' },
        { code: 'TN', name: 'Tunisia' },
        { code: 'TR', name: 'Turkey' },
        { code: 'TC', name: 'Turks and Caicos Islands' },
        { code: 'TV', name: 'Tuvalu' },
        { code: 'UG', name: 'Uganda' },
        { code: 'AE', name: 'United Arab Emirates' },
        { code: 'UM', name: 'United States Minor Outlying Islands' },
        { code: 'UY', name: 'Uruguay' },
        { code: 'UZ', name: 'Uzbekistan' },
        { code: 'VU', name: 'Vanuatu' },
        { code: 'VA', name: 'Vatican City' },
        { code: 'VE', name: 'Venezuela' },
        { code: 'VN', name: 'Vietnam' },
        { code: 'VI', name: 'Virgin Islands of the United States' },
        { code: 'WF', name: 'Wallis and Futuna' },
        { code: 'EH', name: 'Western Sahara' },
        { code: 'YE', name: 'Yemen' },
        { code: 'ZM', name: 'Zambia' },
        { code: 'ZW', name: 'Zimbabwe' },
        { code: 'AX', name: 'Åland Islands' },
        { code: 'KZ', name: 'Kazakhstan' },
        { code: 'BY', name: 'Belarus' },
        { code: 'GE', name: 'Georgia' },
        { code: 'KG', name: 'Kyrgyzstan' },
        { code: 'MN', name: 'Mongolia' },
        { code: 'TM', name: 'Turkmenistan' },
    ];

    private searchCountries: any[] = [
        { code: 'UA', name: 'UA Ukraine' },
        { code: 'EM', name: 'EU EUIPO' },
        { code: 'WO', name: 'WO ВОИС' },
        { code: 'GB', name: 'GB United Kingdom' },
        { code: 'PT', name: 'PT Portugal' },
        { code: 'ES', name: 'ES Spain' },
        { code: 'PL', name: 'PL Poland' },
        { code: 'DE', name: 'DE Germany' },
        { code: 'US', name: 'US United States' },
        { code: 'RO', name: 'RO Romania' },
        { code: 'RS', name: 'RS Serbia' },
        { code: 'PH', name: 'PH Philippines' },
        { code: 'NO', name: 'NO Norway' },
        { code: 'MT', name: 'MT Malta' },
        { code: 'MX', name: 'MX Mexico' },
        { code: 'MA', name: 'MA Morocco' },
        { code: 'SK', name: 'SK Slovakia' },
        { code: 'SI', name: 'SI Slovenia' },
        { code: 'TR', name: 'TR Turkey' },
        { code: 'TN', name: 'TN Tunisia' },
        { code: 'CH', name: 'CH Switzerland' },
        { code: 'SE', name: 'SE Sweden' },
        { code: 'LT', name: 'LT Lithuania' },
        { code: 'LV', name: 'LV Latvia' },
        { code: 'KR', name: 'KR Korea, Republic of' },
        { code: 'JP', name: 'JP Japan' },
        { code: 'IT', name: 'IT Italy' },
        { code: 'IE', name: 'IE Ireland' },
        { code: 'HU', name: 'HU Hungary' },
        { code: 'GR', name: 'GR Greece' },
        { code: 'FR', name: 'FR France' },
        { code: 'FI', name: 'FI Finland' },
        { code: 'EE', name: 'EE Estonia' },
        { code: 'DK', name: 'DK Denmark' },
        { code: 'CZ', name: 'CZ Czech Republic' },
        { code: 'CY', name: 'CY Cyprus' },
        { code: 'HR', name: 'HR Croatia (Hrvatska)' },
        { code: 'BG', name: 'BG Bulgaria' },
        { code: 'AT', name: 'AT Austria' },
        { code: 'CA', name: 'CA Canada' }
    ];

    private regContries: any[] = [
        { code: 'UA', name: 'UA Ukraine' },
        { code: 'EU', name: 'EU European Union' },
        { code: 'US', name: 'US United States (USA)' },
        { code: 'LT', name: 'LT Lithuania' },
        { code: 'CA', name: 'CA Canada' },
        { code: 'UZ', name: 'UZ Uzbekistan' },
        { code: 'KZ', name: 'KZ Kazakhstan' },
        { code: 'BY', name: 'BY Belarus' },
        { code: 'MU', name: 'MU Mauritius' },
        { code: 'EC', name: 'EC Ecuador' },
        { code: 'ZA', name: 'ZA South Africa' },
        { code: 'PY', name: 'PY Paraguay' },
        { code: 'MY', name: 'MY Malaysia' },
        { code: 'MT', name: 'MT Malta' },
        { code: 'AE',  name: 'AE United Arab Emirates' },
        { code: 'JO', name: 'JO Jordan' },
        { code: 'LB', name: 'LB Lebanon' },
        { code: 'TM', name: 'TM Turkmenistan' },
        { code: 'HK', name: 'HK Hong Kong' },
        { code: 'CO', name: 'CO Colombia' },
        { code: 'BO', name: 'BO Bolivia' },
        { code: 'FI', name: 'FI Finland' },
        { code: 'MO', name: 'MO Macao' },
        { code: 'NZ', name: 'NZ New Zealand' },
        { code: 'KW', name: 'KW Kuwait' },
        { code: 'SG', name: 'SG Singapore' },
        { code: 'FJ', name: 'FJ Fiji' },
        { code: 'GE', name: 'GE Georgia' },
        { code: 'PH', name: 'PH Philippines' },
        { code: 'TH', name: 'TH Thailand' },
        { code: 'NO', name: 'NO Norway' },
        { code: 'QA', name: 'QA Qatar' },
        { code: 'GT', name: 'GT Guatemala' },
        { code: 'DZ', name: 'DZ Algeria' },
        { code: 'GB', name: 'GB United Kingdom' },
        { code: 'SE', name: 'SE Sweden' },
        { code: 'CR', name: 'CR Costa Rica' },
        { code: 'SK', name: 'SK Slovakia' },
        { code: 'DK', name: 'DK Denmark' },
        { code: 'TW', name: 'TW Taiwan, Republic of China' },
        { code: 'GY', name: 'GY Guyana' },
        { code: 'PE', name: 'PE Peru' },
        { code: 'KE', name: 'KE Kenya' },
        { code: 'KR', name: 'KR Korea, South' },
        { code: 'IL', name: 'IL Israel' },
        { code: 'HN', name: 'HN Honduras' },
        { code: 'TN', name: 'TN Tunisia' },
        { code: 'CU', name: 'CU Cuba' },
        { code: 'DE', name: 'DE Germany' },
        { code: 'ZW', name: 'ZW Zimbabwe' },
        { code: 'SV', name: 'SV El Salvador' },
        { code: 'YE', name: 'YE Yemen' },
        { code: 'JP', name: 'JP Japan' },
        { code: 'MA', name: 'MA Morocco' },
        { code: 'EG', name: 'EG Egypt' },
        { code: 'CZ', name: 'CZ Czech Republic' },
        { code: 'PA', name: 'PA Panama' },
        { code: 'PL', name: 'PL Poland' },
        { code: 'CD', name: 'CD Congo, DRC' },
        { code: 'AO', name: 'AO Angola' },
        { code: 'ET', name: 'ET Ethiopia' },
        { code: 'IE', name: 'IE Ireland' },
        { code: 'FR', name: 'FR France' },
        { code: 'GR', name: 'GR Greece' },
        { code: 'BD', name: 'BD Bangladesh' },
        { code: 'CH', name: 'CH Switzerland' },
        { code: 'NL', name: 'NL Netherlands' },
        { code: 'AD', name: 'AD Andorra' },
        { code: 'IQ', name: 'IQ Iraq' },
        { code: 'BH', name: 'BH Bahrain' },
        { code: 'PR', name: 'PR Puerto Rico' },
        { code: 'AF', name: 'AF Afghanistan' },
        { code: 'DJ', name: 'DJ Djibouti' },
        { code: 'AW', name: 'AW Aruba' },
        { code: 'BA', name: 'BA Bosnia And Herzegovina' },
        { code: 'LV', name: 'LV Latvia' },
        { code: 'SR', name: 'SR Suriname' },
        { code: 'SY', name: 'SY Syria' },
        { code: 'PK', name: 'PK Pakistan' },
        { code: 'AT', name: 'AT Austria' },
        { code: 'UY', name: 'UY Uruguay' },
        { code: 'MN', name: 'MN Mongolia' },
        { code: 'MX', name: 'MX Mexico' },
        { code: 'MZ', name: 'MZ Mozambique' },
        { code: 'SC', name: 'SC Seychelles' },
        { code: 'RS', name: 'RS Serbia' },
        { code: 'EE', name: 'EE Estonia' },
        { code: 'HR', name: 'HR Croatia' },
        { code: 'ES', name: 'ES Spain' },
        { code: 'PG', name: 'PG Papua New Guinea' },
        { code: 'MK', name: 'MK Macedonia' },
        { code: 'CY', name: 'CY Cyprus' },
        { code: 'PT', name: 'PT Portugal' },
        { code: 'NG', name: 'NG Nigeria' },
        { code: 'VE', name: 'VE Venezuela' },
        { code: 'BR', name: 'BR Brazil' },
        { code: 'NI', name: 'NI Nicaragua' },
        { code: 'DO', name: 'DO Dominican Republic' },
        { code: 'IS', name: 'IS Iceland' },
        { code: 'VN', name: 'VN Vietnam' },
        { code: 'IT', name: 'IT Italy' },
        { code: 'AU', name: 'AU Australia' },
        { code: 'AR', name: 'AR Argentina' },
        { code: 'TR', name: 'TR Turkey' },
        { code: 'SA', name: 'SA Saudi Arabia' },
        { code: 'OM', name: 'OM Oman' },
        { code: 'CL', name: 'CL Chile' },
        { code: 'JM', name: 'JM Jamaica' },
        { code: 'SD', name: 'SD Sudan' },
        { code: 'LY', name: 'LY Libya' },
        { code: 'GH', name: 'GH Ghana' },
        { code: 'IR', name: 'IR Iran' },
        { code: 'BG', name: 'BG Bulgaria' },
        { code: 'SI', name: 'SI Slovenia' },
        { code: 'RU', name: 'RU Russian Federation' },
        { code: 'ID', name: 'ID Indonesia' },
        { code: 'AL', name: 'AL Albania' },
        { code: 'MC', name: 'MC Monaco' },
        { code: 'MD', name: 'MD Moldova' },
        { code: 'IN', name: 'IN India' },
        { code: 'CN', name: 'CN China' },
        { code: 'DM', name: 'DM Dominica' },
        { code: 'NP', name: 'NP Nepal' },
        { code: 'HU', name: 'HU Hungary' },
        { code: 'VG', name: 'VG British Virgin Islands' }
    ];

    public madridCountries = [
        {
            id: '439', country_name: 'African Intellectual Property Organization (OAPI)', country_code: 'OA',
            first: '639', next: '131', registrable: '', classes: '3', weight: '10', inblock: '0'
        },
        {
            id: '440', country_name: 'Albania', country_code: 'AL',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '441', country_name: 'Algeria', country_code: 'DZ',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '442', country_name: 'Antigua and Barbuda', country_code: 'AG',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '443', country_name: 'Armenia', country_code: 'AM',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '444', country_name: 'Australia', country_code: 'AU',
            first: '320', next: '320', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '445', country_name: 'Austria', country_code: 'AT',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '446', country_name: 'Azerbaijan', country_code: 'AZ',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '447', country_name: 'Bahrain', country_code: 'BH',
            first: '274', next: '274', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '450', country_name: 'Bhutan', country_code: 'BT',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '451', country_name: 'Bosnia and Herzegovina', country_code: 'BA',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '452', country_name: 'Botswana', country_code: 'BW',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '540', country_name: 'Brazil', country_code: 'BR',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '453', country_name: 'Bulgaria', country_code: 'BG',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '454', country_name: 'Cambodia', country_code: 'KH',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '542', country_name: 'Chile', country_code: 'CL'
        },
        {
            id: '455', country_name: 'China', country_code: 'CN',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '456', country_name: 'Colombia', country_code: 'CO',
            first: '243', next: '121', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '457', country_name: 'Croatia', country_code: 'HR',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '459', country_name: 'Cyprus', country_code: 'CY',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '460', country_name: 'Czech Republic', country_code: 'CZ',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '461', country_name: 'Democratic People\'s Republic of Korea', country_code: 'KP',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '462', country_name: 'Denmark', country_code: 'DK',
            first: '330', next: '84', registrable: '', classes: '3', weight: '10', inblock: '0'
        },
        {
            id: '463', country_name: 'Egypt', country_code: 'EG',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '464', country_name: 'Estonia', country_code: 'EE',
            first: '151', next: '47', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '465', country_name: 'European Union', country_code: 'EU',
            first: '912', next: '157', registrable: '', classes: '3', weight: '10', inblock: '0'
        },
        {
            id: '466', country_name: 'Finland', country_code: 'FI',
            first: '225', next: '84', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '467', country_name: 'France', country_code: 'FR',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '468', country_name: 'Gambia', country_code: 'GM',
            first: '97', next: '97', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '469', country_name: 'Georgia', country_code: 'GE',
            first: '314', next: '115', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '470', country_name: 'Germany', country_code: 'DE',
            first: '0', next: '0', registrable: '', classes: '0', weight: '-2', inblock: '1'
        },
        {
            id: '471', country_name: 'Ghana', country_code: 'GH',
            first: '379', next: '379', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '472', country_name: 'Greece', country_code: 'GR',
            first: '115', next: '21', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '473', country_name: 'Hungary', country_code: 'HU',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '474', country_name: 'Iceland', country_code: 'IS',
            first: '221', next: '47', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '475', country_name: 'India', country_code: 'IN',
            first: '62', next: '62', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '543', country_name: 'Indonesia', country_code: 'ID'
        },
        {
            id: '476', country_name: 'Iran (Islamic Republic of)', country_code: 'IR',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '477', country_name: 'Ireland', country_code: 'IE',
            first: '257', next: '73', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '478', country_name: 'Israel', country_code: 'IL',
            first: '404', next: '304', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '479', country_name: 'Italy', country_code: 'IT',
            first: '0', next: '0', registrable: '', classes: '0', weight: '-3', inblock: '1'
        },
        {
            id: '481', country_name: 'Kazakhstan', country_code: 'KZ',
            first: '0', next: '0', registrable: '', classes: '0', weight: '-5', inblock: '1'
        },
        {
            id: '482', country_name: 'Kenya', country_code: 'KE',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '483', country_name: 'Kyrgyzstan', country_code: 'KG',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '484', country_name: 'Latvia', country_code: 'LV',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '485', country_name: 'Lesotho', country_code: 'LS',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '486', country_name: 'Liberia', country_code: 'LR',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '487', country_name: 'Liechtenstein', country_code: 'LI',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '488', country_name: 'Lithuania', country_code: 'LT',
            first: '0', next: '0', registrable: '', classes: '0', weight: '-6', inblock: '1'
        },
        {
            id: '490', country_name: 'Madagascar', country_code: 'MG',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '491', country_name: 'Mexico', country_code: 'MX',
            first: '160', next: '160', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '492', country_name: 'Monaco', country_code: 'MC',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '493', country_name: 'Mongolia', country_code: 'MN',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '494', country_name: 'Montenegro', country_code: 'ME',
            first: '0', next: '0', registrable: '', classes: '0', weight: '9', inblock: '0'
        },
        {
            id: '495', country_name: 'Morocco', country_code: 'MA',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '496', country_name: 'Mozambique', country_code: 'MZ',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '497', country_name: 'Namibia', country_code: 'NA',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '499', country_name: 'New Zealand', country_code: 'NZ',
            first: '93', next: '93', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '500', country_name: 'Norway', country_code: 'NO',
            first: '278', next: '78', registrable: '', classes: '3', weight: '10', inblock: '0'
        },
        {
            id: '501', country_name: 'Oman', country_code: 'OM',
            first: '484', next: '484', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '544', country_name: 'Pakistan', country_code: 'PK'
        },
        {
            id: '502', country_name: 'Philippines', country_code: 'PH',
            first: '95', next: '95', registrable: '', classes: '1', weight: '8', inblock: '0'
        },
        {
            id: '503', country_name: 'Poland', country_code: 'PL',
            first: '0', next: '0', registrable: '3', classes: '0', weight: '-7', inblock: '1'
        },
        {
            id: '504', country_name: 'Portugal', country_code: 'PT',
            first: '0', next: '0', registrable: '', classes: '0', weight: '2', inblock: '0'
        },
        {
            id: '505', country_name: 'Republic of Korea', country_code: 'KR',
            first: '233', next: '233', registrable: '', classes: '1', weight: '1', inblock: '0'
        },
        {
            id: '506', country_name: 'Republic of Moldova', country_code: 'MD',
            first: '0', next: '0', registrable: '', classes: '0', weight: '0', inblock: '0'
        },
        {
            id: '507', country_name: 'Romania', country_code: 'RO',
            first: '0', next: '0', registrable: '', classes: '0', weight: '-1', inblock: '0'
        },
        {
            id: '508', country_name: 'Russia', country_code: 'RU',
            first: '0', next: '0', registrable: '2', classes: '0', weight: '-9', inblock: '1'
        },
        {
            id: '509', country_name: 'Rwanda', country_code: 'RW',
            first: '0', next: '0', registrable: '', classes: '0', weight: '3', inblock: '0'
        },
        {
            id: '510', country_name: 'San Marino', country_code: 'SM',
            first: '0', next: '0', registrable: '', classes: '0', weight: '7', inblock: '0'
        },
        {
            id: '511', country_name: 'Sao Tome and Principe', country_code: 'ST',
            first: '0', next: '0', registrable: '', classes: '0', weight: '6', inblock: '0'
        },
        {
            id: '512', country_name: 'Serbia', country_code: 'RS',
            first: '0', next: '0', registrable: '', classes: '0', weight: '5', inblock: '0'
        },
        {
            id: '513', country_name: 'Sierra Leone', country_code: 'SL',
            first: '0', next: '0', registrable: '', classes: '0', weight: '4', inblock: '0'
        },
        {
            id: '514', country_name: 'Singapore', country_code: 'SG',
            first: '272', next: '272', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '515', country_name: 'Slovakia', country_code: 'SK',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '516', country_name: 'Slovenia', country_code: 'SI',
            first: '0', next: '0', registrable: '', classes: '0', weight: '-4', inblock: '1'
        },
        {
            id: '517', country_name: 'Spain', country_code: 'ES',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '518', country_name: 'Sudan', country_code: 'SD',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '519', country_name: 'Swaziland', country_code: 'SZ',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '520', country_name: 'Sweden', country_code: 'SE',
            first: '260', next: '102', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '521', country_name: 'Switzerland', country_code: 'CH',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '522', country_name: 'Syrian Arab Republic', country_code: 'SY',
            first: '66', next: '66', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '523', country_name: 'Tajikistan', country_code: 'TJ',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '545', country_name: 'Thailand', country_code: 'TH'
        },
        {
            id: '524', country_name: 'The former Yugoslav Republic of Macedonia', country_code: 'MK',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '526', country_name: 'Tunisia', country_code: 'TN',
            first: '131', next: '17', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '527', country_name: 'Turkey', country_code: 'TR',
            first: '178', next: '35', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '528', country_name: 'Turkmenistan', country_code: 'TM',
            first: '151', next: '76', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '529', country_name: 'Ukraine', country_code: 'UA',
            first: '0', next: '0', registrable: '4', classes: '0', weight: '-7', inblock: '1'
        },
        {
            id: '541', country_name: 'United Arab Emirates', country_code: 'AE'
        },
        {
            id: '530', country_name: 'United Kingdom', country_code: 'GB',
            first: '262', next: '73', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '531', country_name: 'United States of America', country_code: 'US',
            first: '301', next: '301', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '532', country_name: 'Uzbekistan', country_code: 'UZ',
            first: '1028', next: '103', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '533', country_name: 'Viet Nam', country_code: 'VN',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '534', country_name: 'Zambia', country_code: 'ZM',
            first: '97', next: '58', registrable: '', classes: '1', weight: '10', inblock: '0'
        },
        {
            id: '535', country_name: 'Zimbabwe', country_code: 'ZW',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '536', country_name: 'Benelux', country_code: 'BX',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '537', country_name: 'Belarus', country_code: 'BY',
            first: '0', next: '0', registrable: '1', classes: '0', weight: '-8', inblock: '1'
        },
        {
            id: '538', country_name: 'Canada', country_code: 'CA',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        },
        {
            id: '539', country_name: 'Japan', country_code: 'JP',
            first: '0', next: '0', registrable: '', classes: '0', weight: '10', inblock: '0'
        }
    ];


    constructor() { }

    public getAllCountries(): Observable<SearchCountry[]> {
        return of(this.allCountries);
    }

    public getRegistrationCountries(noUkr=false): Observable<SearchCountry[]> {
        if (noUkr) {
            return of(this.regContries.slice(1));
        } else {
            return of(this.regContries);
        }
    }

    public getSearchCountries(): Observable<SearchCountry[]> {
        return of(this.searchCountries);
    }

    public getMadridCountries(): Observable<MadridCountry[]> {
        return of(this.madridCountries);
    }
}

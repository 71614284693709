import { environment } from 'environments/environment';
import { LocalStorageService } from 'ngx-store';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LoaderInterceptorParams } from '../LoaderInterceptorParams';
import { DrupalizeObject } from '../common/drupalize-object';

interface Pager {
    current_page: number;
    total_items: number;
    total_pages: number;
    items_per_page: number;
}

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    public host: string = environment.host;
    public headers = new HttpHeaders();
    public items: ReplaySubject<any> = new ReplaySubject(1);

    public pager: Pager = {
        current_page: 0,
        total_items: 0,
        total_pages: 0,
        items_per_page: 50
    };
    public model: string;
    public isMobile = false;

    constructor(public http: HttpClient, public storage: LocalStorageService) {}

    public getItems() {
        return new ReplaySubject<any>(1);
    }

    public fetch(page = '0', id = 'all', filters = {}, views_uri = '', separateItems?): void {
        const uri = views_uri + id;
        const params = { _format: 'json', page };
        // clear NULL before send
        this.clearFilters(filters);
        console.log('params:', { ...params, ...filters });
        console.log('views_uri:', uri);
        this.http
            .get<any>(this.host + uri, {
                params: { ...params, ...filters },
                headers: this.headers
            })
            .pipe(map(res => this.prepereRows(res)))
            .subscribe(
                data => {
                    console.log('fetched data', data);
                    if (separateItems) {
                        separateItems.next({
                            rows: data.rows,
                            pager: data.pager
                        });
                    } else {
                        this.items.next(data.rows);
                        if (data.pager) {
                            this.pager = data.pager;
                        }
                    }
                },
                e => {
                    console.log(e);
                    console.log('Can`t reach server :`(');
                }
            );
    }

    public prepereRows(res) {
        if (this.model) {
            const out = [];
            for (const item of res.rows) {
                try {
                    out.push(new this[this.model](item));
                } catch (error) {
                    console.error(error);
                }
            }
            res.rows = out;
        }

        return res;
    }

    public isShortPathType(type) {
        return type === 'objection' || type === 'profit_payment_request' || type === 'portfolio' || type === 'tm_transfer';
    }

    public getOne(id: string, type: string, options?): Observable<any> {
        let uri = '/admin/profit/' + type + '/' + id;
        if ((options && options.shortPath) || this.isShortPathType(type)) {
            uri = '/' + type + '/' + id;
        }
        return this.http.get<any>(this.host + uri, { params: { _format: 'json' }, headers: this.headers });
    }

    public getAll(path, filters = {}) {
        const pageUrl = `${this.host}${path}`;
        this.clearFilters(filters);
        const params = { _format: 'json' };
        return Observable.create(observer => {
            this.http.get<any>(pageUrl, {
                params: { ...params, ...filters },
                headers: this.headers
            })
            .subscribe(
                m => observer.next(m),
                e => observer.error(e),
                () => observer.complete()
            );
        });
    }

    public getTypes(type = '') {
        const uri = '/admin/profit/' + type + '/list';
        return this.http.get<any>(this.host + uri, { params: { _format: 'json' }, headers: this.headers });
    }

    public getUser(id: string): Observable<any> {
        const uri = '/user/' + id;
        return this.http.get<any>(this.host + uri, { params: { _format: 'json' }, headers: this.headers });
    }

    public getUsers(contain?: string): Observable<any> {
        const pageUrl = `${this.host}/admin/profit/users/list/`;
        let params;
        if (contain && contain !== '') {
            params = { _format: 'json', user: contain };
        } else {
            params = { _format: 'json' };
        }
        return Observable.create(observer => {
            this.http.get<any>(pageUrl, {
                params: params,
                // headers: this.headers
            })
            .subscribe(
                m => {
                    for (const item of m) {
                        item['fio'] = item.field_forename + ' ' + item.field_surname;
                    }
                    observer.next(m)
                },
                e => observer.error(e),
                () => observer.complete()
            );
        });
    }

    public getFile(id: string): Observable<any> {
        const uri = '/entity/file/' + id;
        return this.http.get<any>(this.host + uri, { params: { _format: 'json' }, headers: this.headers });
    }

    public addFile(body, id) {
        const uri = '/fileupload/' + id;
        return this.getToken().pipe(
            switchMap(token => {
                const headers = new HttpHeaders();
                headers.set('X-CSRF-Token', token as string);
                return this.http
                    .post(this.host + uri, body, {
                        headers: headers
                    });
            })
        );
    }

    public save(id, payload, type, interceptorOptions?, options?): Observable<any> {
        let uri = '/entity/' + type;
        let method = 'post';
        if (id !== '' && typeof id !== 'undefined' && id !== null) {
            const path = this.isShortPathType(type) ? '/' : '/admin/profit/';
            uri = path + type + '/' + id;
            method = 'patch';
        }
        if (options && options.lang) {
            uri = `/${options.lang}${uri}`;
        }
        if (options && options.method) {
            method = options.method;
        }
        let params = {};
        if (interceptorOptions) {
            params = new LoaderInterceptorParams({...interceptorOptions}, { _format: 'json' });
        } else {
            params = { _format: 'json' };
        }
        return Observable.create(observer => {
            this.http[method](this.host + uri, payload, {
                params,
                headers: this.headers
            }).subscribe(m => observer.next(m), e => observer.error(e), () => observer.complete());
        });
    }

    public saveUser(id, payload): Observable<any> {
        let uri = '/entity/user';
        let method = 'post';
        if (id !== '' && typeof id !== 'undefined' && id !== null) {
            uri = '/user/' + id;
            method = 'patch';
        }
        const drupalizedPayload = DrupalizeObject.format(payload);
        return Observable.create(observer => {
            this.http[method](this.host + uri, drupalizedPayload, {
                params: { _format: 'json' },
                headers: this.headers
            }).subscribe(m => observer.next(m), e => observer.error(e), () => observer.complete());
        });
    }

    public operation(id: number, operation: string, type: string, optionalParams = {}): Observable<any> {
        const uri = '/admin/profit/' + type + '/' + id + '/operations';

        return Observable.create(observer => {
            this.http
                .get(this.host + uri, {
                    params: { _format: 'json', operation, ...optionalParams },
                    headers: this.headers
                })
                .subscribe(m => observer.next(m), e => observer.error(e), () => observer.complete());
        });
    }

    public operationPatch(id: number, operation: string, type: string, data, options?): Observable<any> {
        let uri = '/admin/profit/' + type + '/' + id + '/operations';
        if ((options && options.shortPath) || this.isShortPathType(type)) {
            uri = `/${type}/${id}/operations`;
        }
        let requestOptions = {};
        if (options && options.requestOptions) {
            requestOptions = options.requestOptions;
        }
        let customHeaders;
        if (options && options.customHeaders) {
            customHeaders = options.customHeaders;
        }
        return Observable.create(observer => {
            this.http
                .patch(this.host + uri,
                    data,
                    {
                        params: { _format: 'json', operation },
                        headers: customHeaders ? customHeaders : this.headers,
                        responseType: 'blob'
                    }
                )
                .subscribe(m => observer.next(m), e => observer.error(e), () => observer.complete());
        });
    }

    public delete(id, type, options?): Observable<any> {
        const path = (options && options.shortPath) || this.isShortPathType(type) ? '/' : '/admin/profit/';
        let uri = path + type + '/' + id;
        if (options && options.lang) {
            uri = `/${options.lang}${uri}`
        }
        return Observable.create(observer => {
            this.http
                .delete(this.host + uri, {
                    params: { _format: 'json' },
                    headers: this.headers
                })
                .subscribe(m => observer.next(m), e => observer.error(e), () => observer.complete());
        });
    }

    public getToken() {
        const uri = '/session/token';
        return this.http.get(this.host + uri, {
            params: { _format: 'json' },
            responseType: 'text'
        });
    }

    public uploadFileFromLink(link, name, convert?) {
        const uri = '/filesave';
        const headers = new HttpHeaders();
        return this.getToken().pipe(
            tap((token) => headers.set('X-CSRF-Token', token as string)),
            switchMap(() => this.http.get(link, {responseType: 'blob'})),
            switchMap((file) => {
                const formData = new FormData();
                formData.append('file', file, name);
                if (convert) {
                    formData.append('convert', convert);
                }
                return this.http.post<any>(this.host + uri, formData, {
                    headers: headers
                });
            })
        );
    }

    public uploadFile(file, convert = null, options = null) {
        const uri = '/filesave';
        return this.getToken().pipe(
            switchMap(token => {
                const headers = new HttpHeaders();
                headers.set('X-CSRF-Token', token as string);
                const formData = new FormData();
                formData.append('file', file);
                if (convert) {
                    formData.append('convert', convert);
                }
                let customParams = {};
                if (options && options.showLoader !== undefined) {
                    customParams = {params: new LoaderInterceptorParams({showLoader: options.showLoader}, {})}
                }
                let params = {};
                if (options && options.params) {
                    params = {params: options.params};
                }
                return this.http.post<any>(this.host + uri, formData, {
                    headers: headers, ...customParams, ...params
                });
            })
        );
    }

    public uploadFileArray(files, convert = null) {
        const uri = '/filesave';
        return this.getToken().pipe(
            switchMap(token => {
                const headers = new HttpHeaders();
                headers.set('X-CSRF-Token', token as string);
                const formData = new FormData();
                for (const f of files) {
                    formData.append('file[]', f);
                }
                if (convert) {
                    formData.append('convert', convert);
                }
                return this.http.post<any>(this.host + uri, formData, {
                    headers: headers
                });
            })
        );
    }

    get filters(): any {
        console.log('Fetched filters for: ', this.constructor.name);
        const key = this.constructor.name + '__filters';
        return this.storage ? this.storage.get(key) : '';
    }

    set filters(flters: any) {
        console.log('Setted filters for: ', this.constructor.name);
        const key = this.constructor.name + '__filters';
        if (this.storage) {
            this.storage.set(key, flters);
        }
    }

    public flushFilters(): void {
        const key = this.constructor.name + '__filters';
        this.storage.remove(key);
        console.log('Filter flushed with key', key);
    }

    clearFilters(obj) {
        // Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key]);

        // with recursion
        Object.keys(obj).forEach(key => {
            if (obj[key] && typeof obj[key] === 'object') {
                this.clearFilters(obj[key]);
            } else if (typeof obj[key] === 'boolean') {
                obj[key] = obj[key] ? 1 : 0;
            } else if (obj[key] == null) {
                delete obj[key]
            };
        });
    }

    public getServices(params, bundle = 'all', service_type = 'application'): Observable<any> {
        const uri = '/admin/profit/' + service_type + '/list/' + bundle;
        return Observable.create(observer => {
            this.http
                .get<any>(this.host + uri, { params: { _format: 'json', ...params }, headers: this.headers })
                .subscribe(
                    m => {
                        for (const r of m.rows) {
                            if (!r.target_label && r.tm_name) {
                                r['target_label'] = r.tm_name;
                            } else if (!r.target_label && r.small_title_clean) {
                                r['target_label'] = r.small_title_clean;
                            } else if (!r.target_label && r.small_title) {
                                r['target_label'] = r.small_title;
                            } else if (!r.target_label && r.name) {
                                r['target_label'] = r.name;
                            }
                            if (r.invention_name) {
                                r['target_label'] = r.invention_name;
                            }
                        }
                        observer.next(m.rows);
                    },
                    e => observer.error(e),
                    () => observer.complete()
                );
        });
    }

    public query(path, params) {
        return this.http.get<any>(this.host + path, {
            params: { _format: 'json', ...params },
            // responseType: 'text'
        });
    }

    public sendViberMessage(payload) {
        return this.http.post(this.host + '/api/v1/viber-direct',
            payload, { params: { _format: 'json' }}
        );
    }

    // user registration


    public registerUser(payload): Observable<any> {
        const uri = '/entity/user';
        console.log(payload);
        return this.http.post(this.host + uri, this.formUserPayload(DrupalizeObject.format(payload)), {
            params: { _format: 'json' },
            headers: this.headers
        });
    }

    public formUserPayload(rawPayload) {
        const result = {
            ...rawPayload,
            name: ['email_registration_' + this.makeid()],
            status: [true],
            preferred_langcode: ['uk']
        };
        return result;
    }

    public makeid() {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    public createTranslation(entity_type, id, payload): Observable<any> {
        const uri = `/admin/profit/add_translation/${entity_type}/${id}`;
        return this.http.post(this.host + uri, payload, {
            params: { _format: 'json' },
            headers: this.headers
        });
    }
}
